<template>
    <div class="article-con">
      <ul class="article-box">
        <li :id="'art'+index" style="-webkit-transform:translateZ(0)!important;" v-for="(item,index) in articles" :key="index" class="article-item" @click="$router.push('/article/detail?materialId=' + item.materialId)">
          <div class="text-left">
            <div class="title">{{item.title}}</div>
            <div class="tap">
              <van-tag v-if="item.groups.length > 0" round type="primary" text-color="#55BA95" color="#DCF5EC">{{item.groups[0]}}</van-tag>
              <van-tag v-if="item.groups.length > 1" round type="primary" text-color="#6188B8" color="#DCE9F9">{{item.groups[1]}}</van-tag>
            </div>
          </div>
          <div class="img-right">
            <img :src="item.coverUrl" alt="">
<!--            <div class="img-tap">-->
<!--              火热文章-->
<!--            </div>-->
          </div>
        </li>
        <!-- <li  style="-webkit-transform:translateZ(0)!important;"  class="article-item item-video" >
          <div class="text-left">
            <div class="title">射频消融术 （一）</div>
          </div>
          <div class="img-right">
      
          </div>
        </li> -->
      </ul>

      <div v-if="articles.length > 0" class="no-more">
        <span>---</span>
        {{$t("article.nomore")}}
        <span>---</span>
      </div>
      <div v-else class="no-more">
        <span>---</span>
        {{$t("article.noAppointment")}}
        <span>---</span>
      </div>
    </div>
</template>

<script>

  export default {
    props: ['articles'],
    data() {
      return {
      }
    },
    methods: {
      picSrc: function (val) {
        return val;
      },
      outUrl(item,index) {

        var self=this
        localStorage.setItem('scrollTop',self.scrollTop)
        if(item.contentType && item.contentType ==='outUrl'){

        }
        this.$emit('contentType',true)
        window.location.href = item.url;
      },
      scrollToBot() {
            var self=this
            var top =  localStorage.getItem('scrollTop')
             setTimeout(() => {
                window.scrollTo(0,top)
             }, 100);
      },
      listScrollStart() {

      },
      listScrollEnd(){},
    },
    mounted(){
        var self=this
        document.addEventListener("scroll", function () {
            self.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        });

        this.$nextTick(() => {
            setTimeout(() => {
                if(localStorage.getItem('scrollTop')){
                    this.scrollToBot();
                }

            },100);
        })
    },
    filters: {
      dateFormat: function (val) {
        return ''
      }
    },
  };
</script>

<style scoped lang="less">
  
  .article-con {
    background-color: #ffffff;
    .article-box {
      padding: 0 .6rem;
      .article-item {
        height: 4.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: .04rem solid #E5E7EC;
        .text-left {

          width: 60%;
          margin: 0.5rem 0 0.5rem 0;
          display: flex;
          flex-direction: column;
          height: 3.5rem;
          .title {
            font-size: 0.75rem;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
          .tap {
            .van-tag {
              font-size: 0.5rem;
              margin-right: 0.5rem;
              margin-top: 0.25rem;
              padding: 0.15rem 0.3rem;
            }
          }
        }
        .img-right {
          width: 4rem;
          height: 2.68rem;
          margin-right: 0;
          img {
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;
          }
          /*.img-tap {*/
          /*  position: absolute;*/
          /*  top: .18rem;*/
          /*  left: -0.12rem;*/
          /*  width: 1.8rem;*/
          /*  height: 0.6rem;*/
          /*  background: url("../../../assets/images/tap-long.png") center center*/
          /*  no-repeat;*/
          /*  background-size: 1.8rem .6rem;*/
          /*  color: #FFFFFF;*/
          /*  font-size: .28rem;*/
          /*  text-align: center;*/
          /*  line-height: 0.6rem;*/
          /*}*/
        }
      }
      .item-video{
        display: block;
        .text-left {
          width: auto;
        }
      }
    }
  }
  .no-more {
    color: #C0C4CC;
    font-size: 0.5rem;
    background-color: #ffffff;
    text-align: center;
    line-height: 1rem;
  }
</style>
