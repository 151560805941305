<template>
  <div class="search-container">
    <div class="header-search">
      <div class="search-head">
        <span/>
        <input type="text" :placeholder="$t('article.hintSearch')" v-model="q" @focusin="isSearch=true">
      </div>
      <p @click="handleSearch">{{$t("article.search")}}</p>
    </div>

    <div v-if="isSearch">
      <div class="history-search">
        <p class="history-p" v-if="historySearch.length>0">
          <i>{{$t("article.searchHistory")}}</i>
          <b class="delete" @click="show=true"/>
        </p>
        <ul class="disease-history clearfloat" v-if="historySearch.length>0">
          <li v-for="item in historySearch" @click="getArticles(item)">{{item}}</li>
        </ul>
        <p class="history-p disease-p">
          <i>{{$t("article.groupSearch")}}</i>
        </p>
        <ul class="disease-history clearfloat">
          <li v-for="(item,index) in groups" :key="index" @click="getGroupArticles(item.id)">{{item.name}}</li>
        </ul>
      </div>
      <div class="history-mask" v-if="show"></div>
      <div class="history-hint" v-if="show">
        <p>{{$t('article.deleteAllSearch')}}?</p>
        <div class="history-button">
          <span @click="show=false">{{$t("article.cancle")}}</span>
          <span @click="clearHistory">{{$t("article.confirm")}}</span>
        </div>
      </div>
    </div>

    <div v-else>
      <article-tab :articles='articles' v-if="articles.length>0"/>
    </div>

  </div>
</template>

<script>
  import ArticleTab from "./components/articleTab";

  export default {
    name: 'search',
    components: {ArticleTab},
    data() {
      return {
        q: '',
        gpId: 0,
        historySearch: [],
        show: false,
        groups: [],
        articles: [],
        isSearch: true
      }
    },
    created() {
      this.getGroups()
      this.getHistory()
    },
    methods: {
      filterData() {
        this.articles = []
        this.$axios1.post("/article/list", { gpId: this.gpId,
          q: this.q}).then(res => {
          this.articles = res.data.data
        })
      },
      handleSearch() {
        this.isSearch = false
        if (this.q && this.q.length > 0) {
          this.getHistory();
          this.historySearch.push(this.q);
          var str = JSON.stringify(this.historySearch);
          localStorage.setItem('historySearch', str);
          this.gpId = 0
          this.filterData()
        } else {
          this.q = ''
          this.gpId = 0
          this.filterData()
        }
      },
      getGroups() {
        this.groups = []
        this.$axios1.post("/article/group/query").then(res => {
          this.groups = res.data
        })
      },
      getHistory() {
        if (localStorage.getItem('historySearch')) {
          var arr = JSON.parse(localStorage.getItem('historySearch'));
          this.historySearch = this.unique(arr);
        } else {
          this.historySearch = [];
        }
      },
      clearHistory() {
        localStorage.removeItem('historySearch');
        this.getHistory();
        this.show = false;
      },
      unique(arr) {
        return Array.from(new Set(arr));
      },
      getArticles(q) {
        this.isSearch = false
        this.q = q
        this.gpId = 0
        this.filterData()
      },
      getGroupArticles(gpId) {
        this.isSearch = false
        this.gpId = gpId
        this.filterData()
      }
    }
  };
</script>

<style scoped lang="less">
  .search-container {
    .header-search {
      height: 1.55rem;
      margin: 0.5rem 5% 1rem;
      background: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      .search-head {
        width: 82%;
        height: 1.55rem;
        background: #f3f3f3;
        border-radius: 1.5rem;
        line-height: 1.55rem;
        display: flex;
        flex-direction: row;
       padding-left: 0.5rem;
        align-items: center;

        span {
          display: inline-block;
          width: 0.75rem;
          height: 0.75rem;
          background: url("../../assets/images/search.png") no-repeat;
          background-size: 100% 100%;
        }

        input {
          width: 80%;
          margin-left: 0.5rem;
          background: #f3f3f3;
          height: 1.5rem;
          font-size: 0.7rem;
          line-height: 1.5rem;
          &::placeholder {
            color: #c3c3c3;
          }
        }
      }
      p {
        font-size: 0.75rem;
        color: #00a0e9;
      }
    }


    .history-search {
      width: 94%;
      height: 100%;
      margin-left: 3%;
      margin-right: 3%;
      .history-p {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        i {
          font-size: 0.75rem;
          font-weight: bold;
        }
        b {
          display: inline-block;
          width: 0.65rem;
          height: 0.75rem;
          background: url("../../assets/images/delete.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .disease-history {
        width: 100%;
        margin-top: 0.5rem;
        li {
          background: #fafafa;
          padding: 0.3rem 0.45rem;
          border-radius: 0.75rem;
          font-size: 0.7rem;
          color: #666666;
          float: left;
          margin-right: 0.25rem;
          margin-bottom: 0.25rem;
        }
      }
    }

    .history-mask {
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.5;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 300;
    }

    .history-hint {
      border-radius: 0.15rem;
      width: 50%;
      height: 4rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 400;
      background: #fff;

      p {
        font-size: 0.7rem;
        text-align: center;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
      }

      .history-button {
        width: 100%;
        height: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        span {
          display: inline-block;
          width: 40%;
          height: 100%;
          text-align: center;
          padding: 0.5rem 0.75rem;
          font-size: 0.75rem;

          &:nth-child(1) {
            background: #9a9a9a;
            color: #fff;
          }

          &:nth-child(2) {
            background: #2c8afb;
            color: #fff;
          }
        }
      }
    }
  }
</style>
